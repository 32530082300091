<template> 
<div>
  <v-card class="mx-auto">
    <v-row>
    <v-btn plain class="ma-2" to="/admin/rehab"><v-icon left>mdi-account-convert-outline</v-icon>reingresar usuario</v-btn>
    </v-row>
    <v-divider></v-divider>
    <v-row>
      <v-col cols="12" md="7"><v-btn plain @click="excelex=true">Exportar</v-btn>
     <apexchart ref="grafico1" width="650" height="250" :options="barChartOptions" :series="barSeries" @dataPointSelection="cecosChart()"></apexchart>
     </v-col>
     <v-col cols="12" md="5">
     <!-- <apexchart class="d-none" width="480" :options="pieChartOptions" :series="pieSeries"></apexchart> -->
     </v-col>
     </v-row>
     <v-row>
       <v-col cols="12" >
      <span v-show="cecoChar">
      <apexchart ref="graficoCeco" height="250" :options="barChartOptionsCeco" :series="barSeriesCeco"></apexchart></span>
      </v-col>
     </v-row>
  </v-card>

  <v-dialog v-model="excelex">
        <v-card class="pa-4">
          <v-card-title>Seleccione rango de fechas a exportar</v-card-title>
          <v-row>
              <v-menu
                v-model="sin_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="since"
                    label="Desde"
                    prepend-icon="mdi-calendar-import"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="since"
                  @input="sin_menu = false"
                  :first-day-of-week="1"
                  locale="es-cl"
                ></v-date-picker>
              </v-menu>
              <v-menu
                v-model="unt_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="until"
                    label="Hasta"
                    prepend-icon="mdi-calendar-import"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="until"
                  @input="unt_menu = false"
                  :first-day-of-week="1"
                  locale="es-cl"
                ></v-date-picker>
              </v-menu>
            </v-row>
          <v-card-actions><v-btn plain @click="pageSize = 20000; exportr();">Exportar</v-btn></v-card-actions>
        </v-card>
  </v-dialog>
</div>
</template>

<script>
import C19DataService from "../../services/C19DataService";
import XLSX from 'xlsx';

export default {
  name:"stats",
  data : () => ({
      cecoChar:false,
      excelex:false,
      since:"",
      sin_menu:false,
      until:"",
      unt_menu:false,
      barChartOptions: {
        chart:{ 
          type:'bar',
        },
        toolbar: {
          show: true,
          tools:{download:false}
        },
         title: {
              text: 'Declaraciones por día',
              floating: true,
              offsetY: -7,
              align: 'center',
              style: {
                colors: '#444'
              },
         },
        plotOptions: { bar: { stacked:true } },
        dataLabels: {
              enabled: true,
              textAnchor: 'middle',
              style: {
                colors:['#273746'],
                },
              background:{
                enabled:true,
                opacity:0.3,
                },
            },
        xaxis: {
          categories: ['e']
        },
        yaxis: [{
          title:{
            text:'Respuestas'
          }
        }]
      },
      barSeries: [{
         name: 'Agroindustrial El Paico',
        data: [0],
      }
      ],
    barChartOptionsCeco: {
        chart:{ 
          type:'bar',
        },
        toolbar: {
          show: true,
          tools:{download:false}
        },
        title: {
              text: 'Declaraciones por día',
              floating: true,
              offsetY: -7,
              align: 'center',
              style: {
                color: '#444'
              },
         },
        plotOptions: { bar: { stacked:true } },
        dataLabels: {
              enabled: true,
              textAnchor: 'middle',
              style: {
                colors:['#273746'],
                },
              background:{
                enabled:true,
                colors:'#273746',
                opacity:0.35,
                },
              dropShadow:{
                enabled:true,
                blur:5
              }
            },
        xaxis: {
          categories: ['a','b'],
          labels:{
            rotate: -60,
            hideOverlappingLabels: false,
            showDuplicates: true,
            maxHeight: 150,
            trim:true
            }
        },
        yaxis: [{
          title:{
            text:'Respuestas'
          }
        }]
      },
      barSeriesCeco: [{
         name: 'Agroindustrial El Paico',
        data: [0,0],
      }
      ],
      // pieChartOptions: {
      //       chart: {
      //         width: 380,
      //         type: 'pie',
      //       },
      //       title: {
      //         text: 'Declaraciones por Centro de Costo',
      //         floating: true,
      //         offsetY: -7,
      //         align: 'left',
      //         style: {
      //           color: '#444'
      //         },
      //       },
      //       labels: ['Procesos Posteriores', 'Servicios Generales', 'Mantenimiento', 'Faena', 'Cámara'],
      //       responsive: [{
      //         breakpoint: 480,
      //         options: {
      //           chart: {
      //             width: 200
      //           },
      //           legend: {
      //             position: 'bottom'
      //           }
      //         }
      //       }]
      //     },
      // pieSeries: [44, 55, 13, 43, 22],
  }),
  computed: {
    
  },
  methods: {
    exportr() {
      let data = {}
      data['val']="paico"
      data['since']= this.since
      data['until']= this.until
      C19DataService.getRespuestas(data)
        .then((response) => {
          var arrrespuesta =[]
            for (let i = 0; i < response.data.length; i++) {
            const arrresp = {
              "Fecha":  response.data[i]['dia_fecha'],
              "RUT": response.data[i]['rut'],
              "Nombre": response.data[i]['nombre'],
              "Contacto_24h":  response.data[i]['pregunta1'],
              "Sintomas":  response.data[i]['pregunta2'],
              "PCR_Familia":  response.data[i]['pregunta3'],
              "Estado_OK":  response.data[i]['confirmacion'],
              
            }
            arrrespuesta.push(arrresp)
            }
          var fecha = Date.now()
          var codig = XLSX.utils.json_to_sheet(arrrespuesta)
          var wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb,codig,'Respuestas')
          XLSX.writeFile(wb, `respuestas${fecha}.xlsx`)
        })
        .catch((e) => {
          console.log(e);
        });
    },

    updateChart () {
     let params = {}
     params['val']="paico"
           
      C19DataService.getStats( params ) 
          .then((response) => {
            this.barSeries[0] = {data:response.data[0].resptas}
            this.barChartOptions.xaxis = [{categories:response.data[0].categoria}]
            this.$refs.grafico1.updateOptions({
              xaxis: {
                categories : this.barChartOptions.xaxis[0].categories
              },
            })
    }) 
    },
    
    async cecosChart () {
      var evchar = this.$refs.grafico1.chart.w.globals.selectedDataPoints[0][0]
      console.log(evchar)
    if(evchar){
      this.cecoChar=true
      var diaSel =this.barChartOptions.xaxis[0].categories[evchar]
      console.log(diaSel)

     let params = {}
     params['val']="paico"
     params['dia']=diaSel
           
    await C19DataService.getStatsCeco( params ) 
          .then( (response) => {
            console.log(response.data)
            this.barSeriesCeco[0] = {data:response.data[0].resptas}
            this.barChartOptionsCeco.xaxis = [{categories:response.data[0].n_ceco}]
            this.$refs.graficoCeco.updateOptions({
              xaxis: {
                categories : this.barChartOptionsCeco.xaxis[0].categories
              },
            })
            this.$refs.graficoCeco.updateSeries([{
              data : this.barSeriesCeco[0].data
            }])
            console.log(this.barSeriesCeco)
        })
    }else{

      this.cecoChar=false
      this.$refs.graficoCeco.resetSeries()

    //        let params = {}
    //  params['val']="paico"
    //  params['dia']="out"
           
    // await  C19DataService.getStats( params ) 
    //       .then((response) => {
    //         // console.log(response.data)
    //         this.barSeriesCeco[0] = {data:response.data[0].resptas}
    //         this.barChartOptionsCeco.xaxis = [{categories:response.data[0].n_ceco}]
    //         this.$refs.graficoCeco.updateOptions({
    //           xaxis: {
    //             categories : this.barChartOptionsCeco.xaxis[0].categories
    //           },
    //         })
    //     })
    } 
    },

  },


  mounted () {
    this.updateChart();
  }
}
</script>